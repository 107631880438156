import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import '../../assets/styles/base/standard-page.scss';
import '../../assets/styles/pages/accident-management.scss';
import Layout from '../../components/layout';
import SubNav from '../../components/sub-nav';
import SEO from '../../components/seo';
import CorporateFleetsHeader from '../../images/crash-management/CorporatClientFleets-Header.png'
import AccidentSubNav from "../../components/accident-sub-nav";

const CorporateGovernmentFleets = () => {
  const data = useStaticQuery(graphql`
    query {
      general {
        country_code
      }
    }
  `);
  if (data.general.country_code === 'nz') {
    return (
      <Layout wrapperClass="standard-page" showSiteIndex={true} hideHeaderFooter={true}>
        <SEO title="DingGo | Corporate &amp; Government fleet collision repair management services" />
        <AccidentSubNav/>
        <div className="hero-image">
          <div className="container">
            <img src={CorporateFleetsHeader}/>
          </div>
        </div>
        <div className="main-wrapper">
          <section className="py-7">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h1>Corporate &amp; Government fleet collision repair management services</h1>

                  <div className="entry-content" itemProp="mainContentOfPage">
                    <p>Crash Management has been serving corporate and government fleets since 2004. Some of New
                      Zealand’s
                      most prestigious blue-chip corporates rely on us to develop and deliver customised programmes to
                      suit their unique business and operations requirements. Crash Management also serves a range of
                      Government, local Council and Crown Institute fleets. We can deliver a Health &amp; Safety
                      compliant
                      driver support facility, ensure the organisation is fully fleet fit with safety standards to suit
                      the most demanding environments, and provide robust management reporting.</p>
                    <p>Crash Management won the prestigious <strong>Insurance Industry Award 2009 for Innovation of the
                      Year</strong>. We can deliver a stand-alone best-of-breed accident management service, or
                      integrate
                      with other alliance partners to develop comprehensive turn-key fleet management solutions.</p>
                    <h3>Introduction</h3>
                    <p>Crash Management provides independent accident management &amp; collision repair services to
                      fleets&nbsp;of all sizes. A small sample of references is provided on
                      our Testimonials page.
                      We work closely with a range of alliance partners in the car leasing, car manufacturing and
                      insurance broker sectors.</p>
                    <p>Our value proposition is simple – professional fleet management saves clients time, resources and
                      money, and protects driver safety. Accident response and collision repair administration is
                      time-consuming and requires specialised expertise to ensure consistent technical and service
                      quality. It is a non-core activity for most businesses that consumes non-productive time, so is an
                      obvious out-sourcing opportunity for all fleets.</p>
                    <p>In addition to our core accident management offering, we also provide a range of service
                      extension
                      options and can fully customise a programme to suit your unique operational requirements. This
                      will
                      ensure the organisation is fleet fit and compliant &nbsp;with the Health &amp; Safety at Work Act
                      legislation that applies to all aspects of business including driving risk. Standard Operating
                      Procedures can be developed to suit the most demanding fleet requirements, ensure consistency and
                      compliance throughout the country. Our bundled services can be delivered at very competitive
                      rates.</p>
                    <p>If you’d like to know more about Crash Management Services contact us for a confidential
                      no-obligation discussion at <a
                        href="mailto:sales@crashmanagement.co.nz">sales@crashmanagement.co.nz.</a></p>
                    <h3>Service summary</h3>
                    <p>Crash Management provides a unique 24/7 nationwide accident management solution, along with a
                      range
                      of tools that have been proven to reduce incident rates. A tailored accident management programme
                      will co-ordinate all logistics, communications and repairs within the end-to-end exercise. The
                      focus
                      is on optimising time and efficiencies by linking seamlessly with other key stakeholders in the
                      supply chain. This includes insurance brokers, assessors, and an extensive network of high-quality
                      certified collision repair specialists that are accepted by all insurance companies. Out-sourced
                      accident management reduces administration time for management and drivers, and improves
                      operational
                      efficiency by minimising delays and vehicle time off the road.</p>
                    <p>Crash Management’s customer satisfaction and service offering is superior to all market
                      competitors, and includes provision of free late-model replacement vehicles. See more at <a
                        href="https://dinggo.co.nz/about"><u>About Us</u></a>. The resources, expertise and
                      benefits provided significantly reduces driver inconvenience and ensures seamless mobility to
                      minimise business disruption.</p>
                    <ul>
                      <li>24/7 nationwide toll-free service – maximum caller response time 60 seconds</li>
                      <li>Emergency services co-ordination: Police, Ambulance, Fire</li>
                      <li>Salvage &amp; towing co-ordination (cost of towing is billed direct to your insurer)</li>
                      <li>Pro-active timely communications – coordinating all parties including customers, insurance
                        companies, assessors, repairers, and our rental car provider
                      </li>
                      <li>Free courtesy cars in most locations</li>
                      <li>Free rental cars, SUVs, utes &amp; vans for all not-at-fault drivers&nbsp;– <em>subject to the
                        third party’s insurer accepting liability for the accident.</em></li>
                      <li>Co-ordination of quality damage repairs to manufacturers’ specifications, to ensure the
                        on-going
                        safety of the vehicle
                      </li>
                      <li>Daily progress tracking and pro-active status updates to customers</li>
                      <li>Customer log-in access to specialised accident management cloud-based portal for full
                        transparency &amp; permanent record keeping
                      </li>
                      <li>Windscreen/glass claim co-ordination and on-site replacements (workplace or home)</li>
                      <li>All insurance broker &amp; insurance company clients served, except Protecta Insurance</li>
                    </ul>
                    <p>Sophisticated incident data analysis is used to understand the profile of drivers, the fleet, and
                      the operating environment.&nbsp; The resulting intelligence supports targeted remedial actions
                      that
                      are proven to reduce cost in all aspects of the business.</p>
                    <h3>Management Reports</h3>
                    <p>A wide range of management reporting options is available and can be tailored to suit. This
                      includes simple monthly activity summary and claim cycle-time reports. More complex options
                      include
                      incident data that analyses accident causes. This risk management tool identifies common incident
                      causes including recidivist drivers, other factors and trends. This supports targeted remedial
                      action that has been proven to reduce accident rates over time, resulting in insurance premium
                      savings. Lower incident rates result in fewer driver injuries, less time off work and reduced
                      business disruption. Information is power.</p>
                    <h3>Health &amp; Safety</h3>
                    <p>A mobile workforce is the back-bone of the economy and must be protected. Any vehicle used
                      for&nbsp;business purposes is deemed to be&nbsp;<em>“</em>a place of work<em>”</em>&nbsp;so fleet
                      operations are subject to the H&amp;S obligations imposed by the Act. In addition to
                      identification
                      of any hazard (a motor vehicle/driving) this includes a requirement to identify the probability of
                      accident or harm, or “risk”, and develop appropriate responses. In order to comply any fleet
                      operator must demonstrate best practice in fit-for-purpose vehicle selection and maintenance
                      safety,
                      and develop robust driver safety protocols. In addition to a tailored Vehicle Use Policy this can
                      include a formal Driver Rescue &amp; Repatriation Plan, and the resources to provide robust 24/7
                      response. A specialist accident management provider can deliver all this, instant injury
                      notifications, comprehensive management reporting, targeted remedial action that is proven to
                      reduce
                      incidents, and more.</p>
                    <p>Talk to us now about how Crash Management can help compliance in vehicle fleet health and safety,
                      and deliver full driver support services for your organisation.</p>


                    <div
                      className="wp-block-columns is-layout-flex wp-container-core-columns-layout-1 wp-block-columns-is-layout-flex">
                      <div className="wp-block-column is-layout-flow wp-block-column-is-layout-flow">
                        <h3 className="wp-block-heading">Our technical team &amp; systems</h3>


                        <p>Our friendly Operation staff “The Crash Team” have extensive insurance or trade-based
                          experience including the collision repair sector. &nbsp;These technical skills combined with
                          extensive in-house training delivers Crash Management “extreme service” every time. Your
                          dedicated Case Manager will be your advocate throughout the end-to-end accident and collision
                          repair experience.</p>


                        <p>State-of-the-art telephone technology and software systems ensure prompt dependable response
                          from the Crash Team. We do not use machine screening, recorded menus or off-shore
                          call-centres.
                          Operational assistance is available during business hours at&nbsp;<a
                            href="mailto:crashteam@crashmanagement.co.nz?Subkect=Enquiry%20from%20Crash%20Management%20website">crashteam@crashmanagement.co.nz</a>&nbsp;or
                          0800 2CRASH including after-hours and emergency response.</p>


                        <p>Our processes are cloud-based and real-time so we can capture all the information we require
                          in
                          just a few minutes using client-specific templates to ensure reliability and consistency. This
                          enables us to maximise customer service and response times including dispatch of emergency
                          services, salvage, repairer selection based on known availability, and courtesy car delivery.
                          Our platform ensures clarity, provides a permanent record, is fully transparent and auditable.
                          The new case is uploaded instantly which triggers an automated email notification to all
                          stakeholders.</p>
                      </div>

                    </div>


                    <h3>Cost</h3>
                    <p>In addition to our core accident management offering, we also provide a range of service
                      enhancements, benefits and reporting packages with the most competitive pricing in the New Zealand
                      market. Talk to us now. We’re eager to understand your unique requirements in order to develop a
                      proposal that will deliver the ideal service solution and ensure your business is fleet fit.</p>
                    <h3>Talk to us now</h3>
                    <p>For a confidential no-obligation discussion about your fleet requirements contact <a
                      href="mailto:sales@crashmanagement.co.nz">sales@crashmanagement.co.nz</a> now.</p>
                    <h3>Case study</h3>
                    <p>A case study focused on the fleet efficiencies delivered to a local Council client was published
                      in Local Government Contact magazine. Prior to
                      outsourcing to Crash Management the client had 2.5 FTE’s involved in managing the fleet of less
                      than
                      150. This included organising accidents and repairs manually using a paper-based system with
                      limited
                      reporting. Crash Management was tasked with delivering a service that would provide instant 24/7
                      driver response in emergencies, and take full responsibility for the end-to-end accident process.
                      This involved co-ordination of assessing, repairs and all logistics including guaranteed delivery
                      of
                      late-model courtesy cars.</p>
                    <p>The objective was to reduce the client’s over-all time and cost for management, staff and
                      drivers.
                      Another key requirement was an improvement in fleet utilisation while improving both technical and
                      customer service standards. We achieved this by stream-lining processes and delivering service
                      over
                      our cloud-based portal. The client had secure log-in access and complete control with minimal
                      input,
                      and automated monthly summary reporting improved information flows. An improvement in driver
                      responsibility and accountability was also achieved, along with a significant reduction in
                      incident
                      rates. This case study provides a clear example of how we can add value for any &nbsp;organisation
                      aspiring to be fully fleet fit.</p></div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
  if (data.general.country_code === 'au') {
    return (
      <Layout wrapperClass="standard-page" showSiteIndex={true} hideHeaderFooter={true}>
        <SEO title="DingGo | Corporate &amp; Government fleet collision repair management services" />
        <AccidentSubNav/>
        <div className="hero-image">
          <div className="container">
            <img src={CorporateFleetsHeader}/>
          </div>
        </div>
        <div className="main-wrapper">
          <section className="py-7">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h1>Corporate &amp; Government Fleet Collision Repair Management Services by DingGo</h1>

                  <div className="entry-content" itemProp="mainContentOfPage">
                    <p>DingGo has been a trusted partner for corporate and government fleets since 2018. Renowned
                      blue-chip corporates in Australia rely on DingGo to tailor and implement customised programs
                      that align seamlessly with their unique business and operational needs. Our comprehensive
                      services extend to Federal Government, State Government and Local Councils, providing a health
                      &amp; safety compliant driver support facility, ensuring fleet fitness with safety standards
                      for challenging environments, and delivering robust management reporting.</p>
                    <p>DingGo, a recipient of the prestigious Paint and Panel Industry Award for Innovation in 2019,
                      excels in delivering standalone, best-of-breed accident management services. We also collaborate
                      with alliance partners to develop turn-key fleet management solutions.</p>
                    <h3>Introduction</h3>
                    <p>
                      DingGo specialises in independent accident management and collision repair services for fleets
                      of all sizes.  Our core value proposition is straightforward: professional fleet management
                      with DingGo saves time, resources, and money while prioritising driver safety and convenience.
                      Outsourcing accident response and collision repair administration allows businesses to focus on
                      their core activities. Apart from our core services, DingGo offers service extension options and
                      fully customisable programs to meet unique operational requirements.
                    </p>
                    <p>If you want to learn more about DingGo services, contact us for a confidential, no-obligation
                      discussion at <a href="mailto:sales@dinggo.com.au">sales@dinggo.com.au</a></p>
                    <h3>Service summary</h3>
                    <p>
                      DingGo provides a unique nationwide accident management solution, proven to reduce incident
                      rates. Our tailored accident management program efficiently coordinates logistics,
                      communications, and repairs. We optimize time and efficiency by seamlessly linking with key
                      stakeholders in the supply chain, including insurers, brokers, assessors, and a network of
                      certified collision repair specialists accepted by all insurance companies.
                    </p>
                    <p>DingGo boasts superior customer satisfaction and service offerings.</p>
                    <h3>Key Features of DingGo's Services:</h3>
                    <ul>
                      <li>Nationwide toll-free service with a maximum caller response time of 60 seconds</li>
                      <li>Salvage and towing coordination (towing costs billed directly to your insurer)</li>
                      <li>Proactive and timely communications coordinating all parties involved.</li>
                      <li>Replacements cars in most locations which can include drop off and pick up.</li>
                      <li>Quality damage repairs to manufacturers' specifications</li>
                      <li>Quality damage repairs to your fleet' specifications (Inc signage, Decals & Stickers)</li>
                      <li>Daily progress tracking and proactive status updates</li>
                      <li>Customer log-in access to a specialised cloud-based portal for transparency and permanent
                        record-keeping</li>
                      <li>Windscreen/glass claim coordination and on-site replacements</li>
                    </ul>
                    <h3>Management Reports</h3>
                    <p>
                      DingGo offers a wide range of management reporting options tailored to your needs. From simple
                      monthly activity summaries to complex incident data analysis, our risk management tools help
                      identify common incident causes and support targeted remedial actions proven to reduce costs
                      and improve safety.
                    </p>
                    <h3>Health &amp; Safety</h3>
                    <p>
                      DingGo recognizes the importance of protecting a mobile workforce. We assist fleet operators
                      in complying with health & safety obligations by delivering best practices in vehicle selection,
                      maintenance safety, and driver safety protocols as well as driver data to identify all drivers
                      in breach of the fleets acceptable incident rates.
                    </p>
                    <h3>Our technical team &amp; systems</h3>
                    <p>
                      Our dedicated team, "The DingGo Team," comprises professionals with extensive experience in
                      insurance, trade, and collision repair sectors. Our state-of-the-art telephone technology and
                      software systems ensure prompt, dependable responses. We prioritize a human touch, eliminating
                      machine screening and maximising access to information for users.
                    </p>
                    <h3>Cost</h3>
                    <p>
                      In addition to our core accident management services, DingGo offers a range of service
                      enhancements, benefits, and reporting packages at competitive prices. Contact us to discuss
                      your unique requirements and receive a customized proposal for your fleet.
                    </p>
                    <h3>Talk to us now</h3>
                    <p>For a confidential, no-obligation discussion about your fleet requirements,
                      contact <a href="mailto:sales@dinggo.com.au">sales@dinggo.com.au</a></p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
};

export default CorporateGovernmentFleets;